import React from "react"
import Transition from "./Transition/Transition"
import Menu from "src/components/GlobalModules/Menu"
import Helmet from "react-helmet"
import { getSeoData } from "src/helpers/getSeoData"
import SeoWrapper from "src/components/SeoWrapper/SeoWrapper"
import isNode from "is-node"
import CookieBar from "src/components/GlobalModules/CookieBar"
import BedowTextHeadWoff2 from "src/assets/fonts/Bedow/BedowText-Head.woff2"
import BedowTextHandWoff2 from "src/assets/fonts/Bedow/BedowText-Hand.woff2"
import BedowDisplayHeadWoff2 from "src/assets/fonts/Bedow/BedowDisplay-Head.woff2"
import BedowDisplayHandWoff2 from "src/assets/fonts/Bedow/BedowDisplay-Hand.woff2"

export default ({ children, location }) => {
  const seo = Object.assign(
    {},
    children.props && children.props.pageContext
      ? children.props.pageContext.global_seo
      : {}
  )

  const seoData = getSeoData([seo.content])
  return (
    <React.Fragment>
      <Helmet
        defaultTitle={"Bedow"}
        titleTemplate={`Bedow – %s`}
        type="website"
      >
        <html lang="sv" />
        <link
          href="https://www.google-analytics.com"
          rel="preconnect"
          crossorigin
        />
        <link
          rel="preload"
          as="font"
          type="font/woff2"
          href={BedowTextHeadWoff2}
          crossorigin="anonymous"
        />
        <link
          rel="preload"
          as="font"
          type="font/woff2"
          href={BedowTextHandWoff2}
          crossorigin="anonymous"
        />
        <link
          rel="preload"
          as="font"
          type="font/woff2"
          href={BedowDisplayHeadWoff2}
          crossorigin="anonymous"
        />
        <link
          rel="preload"
          as="font"
          type="font/woff2"
          href={BedowDisplayHandWoff2}
          crossorigin="anonymous"
        />
      </Helmet>
      <SeoWrapper {...seoData} />
      <Transition location={location}>{children}</Transition>
      {!isNode && <CookieBar />}
      <Menu location={location} />
    </React.Fragment>
  )
}
