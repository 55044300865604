import React from "react"
import {
  TransitionGroup,
  Transition as ReactTransition,
} from "react-transition-group"
import styled from "styled-components"

const Wrapper = styled.div`
  transition: opacity 0.3s cubic-bezier(0, 0, 0, 0.7);
  opacity: 0;
  background: white;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
`

const getTransitionStyles = {
  entering: {
    position: `absolute`,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0,
  },
  entered: {
    opacity: 1,
  },
  exiting: {
    opacity: 0,
  },
}

class Transition extends React.PureComponent {
  render() {
    const { children, location } = this.props

    return (
      <TransitionGroup>
        <ReactTransition
          key={location ? location.pathname : children.key}
          timeout={{
            enter: 500,
            exit: 300,
          }}
        >
          {status => (
            <Wrapper
              style={{
                ...getTransitionStyles[status],
              }}
            >
              {children}
            </Wrapper>
          )}
        </ReactTransition>
      </TransitionGroup>
    )
  }
}

export default Transition
