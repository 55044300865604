import React from "react"
import { Location } from "@reach/router"
import styled, { ThemeProvider } from "styled-components"
import { CookiesProvider } from "react-cookie"

import theme from "src/helpers/theme"

import "src/assets/styles/reset.scss"
import "src/assets/styles/body.scss"
import fonts from "src/assets/fonts/fonts"

const AppStyles = styled.div`
  ${fonts}
  ${props => props.theme.mixins.fontBedowTextHead}
  ${props => props.theme.mixins.fontSizeBody}
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "calt" 1, "onum" 1;

  * {
    box-sizing: border-box;
  }

  img {
    width: 100%;
    height: auto;
    display: block;
  }

  a {
    color: black;
  }

  p {
      max-width: 35em;
  }

  .o-image {
    &:not(.critical) {
      ${props => props.theme.mixins.transition("opacity")}
      opacity: 0;
    }
  }
`

export default ({ element }) => {
  return (
    <CookiesProvider>
      <ThemeProvider theme={theme}>
        <Location>
          {({ location }) => {
            return <AppStyles>{element}</AppStyles>
          }}
        </Location>
      </ThemeProvider>
    </CookiesProvider>
  )
}
