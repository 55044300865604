import styled from "styled-components"
import Flex from "src/components/UI/Layout/Flex"

const Column = styled(Flex)``

Column.defaultProps = {
  flexDirection: "column",
  px: ["6px", null, null, "12px"],
}

export default Column
