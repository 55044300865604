export const getSeoData = data => {
  if (!data) {
    return {}
  }
  if (data && data.length > 0 && data[0]) {
    const { title, description, share_image } = data[0]

    return {
      title: title ? title : null,
      description: description ? description : null,
      ogImage: share_image ? share_image : null,
    }
  }
  return {}
}
