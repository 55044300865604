import Flex from "src/components/UI/Layout/Flex"
import styled from "styled-components"

const Container = styled(Flex)``

Container.defaultProps = {
  width: 12 / 12,
  flexDirection: "column",
  px: ["6px", null, null, "12px"],
  mx: "auto",
}

export default Container
