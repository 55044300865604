import styled from "styled-components"
import Box from "src/components/UI/Layout/Box"

const StyledLink = styled(Box)`
  a,
  a:hover,
  a:visited,
  a:active {
    ${props =>
      props.theme.mixins.transition(
        "font-variation-settings",
        "0.2s",
        "cubic-bezier(0, 0, 0, 0.8)"
      )}
    position: relative;
    color: ${props => props.theme.colors.black};
    text-decoration: none;

    ::after {
      content: "";
      display: block;
      position: absolute;
      bottom: -1px;
      left: 0;
      right: 0;
      width: 100%;
      height: 1px;
      background: ${props => props.theme.colors.black};
    }
  }

  @media (min-width: ${props => props.theme.breakpoints[2]}) {
    a:hover {
      &:hover {
        font-variation-settings: "GRAD" 100;
      }
    }
  }
`

StyledLink.defaultProps = {}

export default StyledLink
