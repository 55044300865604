import React from "react"
import { Link } from "gatsby"

const LinkComponent = ({
  blok,
  to,
  text,
  className = "",
  children,
  onClick,
  style,
}) => {
  const { link, text: linkText } = blok ? blok : {}
  let title = text ? text : linkText ? linkText : children
  const content = children ? children : text ? text : linkText ? linkText : ""
  if (typeof title !== "string") {
    title = ""
  }

  if (to) {
    if (!to.endsWith("/")) {
      to = to + "/"
    }
    return (
      <Link
        style={style}
        className={className}
        onClick={onClick}
        to={to}
        title={title}
      >
        {content}
      </Link>
    )
  } else {
    if (link && link.cached_url && !link.cached_url.endsWith("/")) {
      link.cached_url = link.cached_url + "/"
    }

    if (link && link.cached_url.endsWith("home/")) {
      link.cached_url = ""
    }

    return link.linktype && link.linktype === "url" ? (
      <a
        rel="noopener noreferrer"
        className={className + " o-link"}
        onClick={onClick}
        title={title}
        target="_blank"
        href={link.url}
      >
        {content}
      </a>
    ) : (
      <Link
        className={className + " o-link"}
        onClick={onClick}
        title={title}
        to={`/${link.cached_url}`}
      >
        {content}
      </Link>
    )
  }
}

export default LinkComponent
