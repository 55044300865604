const theme = {
  breakpoints: ["576px", "768px", "992px", "1280px", "1600px"],
  colors: {
    black: "#000000",
    white: "#FFFFFF",
    beige: "#F4ECD5",
    green: "#747d79",
  },
  space: {
    0: "0px",
    1: "8px",
    2: "16px",
    3: "24px",
    4: "32px",
    5: "40px",
    6: "48px",
    7: "56px",
    8: "64px",
    9: "72px",
    10: "80px",
    11: "88px",
    12: "96px",
    13: "104px",
    14: "112px",
    15: "120px",
    16: "128px",
    17: "136px",
    18: "144px",
    19: "152px",
    20: "160px",
    21: "220px",
    22: "240px",
    23: "280px",
  },
  fontSizes: {
    0: "16px",
  },
  mixins: {
    fontBedowTextHead: () => `
        font-family: "Bedow-Head-Text", Arial, sans-serif;

        /* Use variable font in supported browsers */
        @supports (font-variation-settings: normal) {
          font-family: "Bedow-Text-Head", "Bedow-Head-Text", Arial, sans-serif;
          font-variation-settings: "GRAD" 0; /* Bedow Head */
        }
      `,
    fontBedowTextHand: () => `
      font-family: "Bedow-Hand-Text", Arial, sans-serif;

      /* Use variable font in supported browsers */
      @supports (font-variation-settings: normal) {
        font-family: "Bedow-Text-Hand", "Bedow-Hand-Text", Arial, sans-serif;
        font-variation-settings: "GRAD" 100; /* Bedow Hand */
      }
    `,
    fontBedowDisplayHead: () => `
        font-family: "Bedow-Head-Display", Arial, sans-serif;

        /* Use variable font in supported browsers */
        @supports (font-variation-settings: normal) {
          font-family: "Bedow-Display-Head", "Bedow-Head-Display", Arial, sans-serif;
          font-variation-settings: "GRAD" 0; /* Bedow Head */
        }
      `,
    fontBedowDisplayHand: () => `
      font-family: "Bedow-Hand-Display", Arial, sans-serif;

      /* Use variable font in supported browsers */
      @supports (font-variation-settings: normal) {
        font-family: "Bedow-Display-Hand", "Bedow-Hand-Display", Arial, sans-serif;
        font-variation-settings: "GRAD" 100; /* Bedow Hand */
      }
    `,
    fontSizeBody: () => `
      font-size: 16px;
      line-height: 1.23;
    `,
    buttonDefault: () => `
      width: auto;
      -webkit-appearance: button;
      background-color: transparent;
      border: none;
      box-shadow: none;
      cursor: pointer;

      &:disabled {
        opacity: 0.54;
      }
      `,
    transition: (
      property = "all",
      timing = "0.3s",
      easing = "cubic-bezier(0.42, 0, 0.35, 1)",
      delay = "0s"
    ) => `
      transition: ${property} ${timing} ${easing} ${delay};
      `,
    imageObjectFit: (position = "center") => `
      object-fit: cover;
      object-position: ${position};
      `,
    positionCover: () => `
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      `,
    overlay: (color = "rgba(0,0,0,0.2)") => `
      content: "";
      background-color: ${color};
      `,
    textElipsis: () => `
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      `,
  },
}

export default theme
