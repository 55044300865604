export const checkStrong = string => {
  if (string) {
    const strongMarkdown = string.substring(0, 2)
    if (strongMarkdown === "**") {
      string = string.substring(2)
      string = string.substring(string.length - 2, 0)
    }
  }

  return string
}
