import React from "react"
import SbEditable from "storyblok-react"
import styled from "styled-components"
import ReactMarkdown from "react-markdown/with-html"
import StyledLink from "src/components/UI/Elements/StyledLink"
import { LinkRenderer } from "src/helpers/markdownRenderers"

const StyledMarkdown = styled.div`
  p {
    margin-bottom: ${props => props.theme.space[2]};

    @media (min-width: ${props => props.theme.breakpoints[2]}) {
      margin-bottom: ${props => props.theme.space[3]};
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`
const Markdown = ({ blok, escapeHtml = false }) => {
  return (
    <SbEditable content={blok}>
      <StyledMarkdown>
        <StyledLink>
          <ReactMarkdown
            renderers={{ link: LinkRenderer }}
            escapeHtml={escapeHtml}
            className="markdown"
            source={blok.text}
          />
        </StyledLink>
      </StyledMarkdown>
    </SbEditable>
  )
}

export default Markdown
