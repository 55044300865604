export const checkColorHex = (color, type) => {
  const pattern = /^#([0-9A-F]{3}){1,2}$/i
  const isValid = color ? pattern.test(color) : null
  const validColor = isValid
    ? color
    : type === "background"
    ? "#ffffff"
    : type === "text"
    ? "#000000"
    : "#ffffff"
  return validColor
}
