import React from "react"
import styled from "styled-components"
import sprite from "./sprite-multi.png"
import spriteWebp from "./sprite-multi.webp"
import Lottie from "react-lottie"
import * as animationData from "./lottie-sleepy.json"
import isNode from "is-node"

const defaultOptions = {
  loop: false,
  autoplay: false,
  animationData: animationData.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
}

const Wrapper = styled.div`
  z-index: 5;
`

const css = () => {
  let string = ""
  const leftPerStep = -101
  for (let index = 1; index <= 17; index++) {
    let left = 8
    if (index > 1) {
      left = leftPerStep * (index - 1) + 6
    }
    const current = `
      &[data-active="${index}"] {
        left: ${left}px;
      }
    `
    string = string + current
  }
  return string
}

const Icon = styled.div`
  ${props =>
    props.theme.mixins.transition(
      "transform",
      "0.15s",
      "cubic-bezier(0.2, 0, 0, 0.6)"
    )}
  width: 46px;
  height: 46px;
  position: relative;

  .hand-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  #hand-sprite {
    position: absolute;
    top: 2px;
    left: 8px;
    width: 1650px;

    ${css}
  }

  .z {
    display: none;
    position: absolute;
    top: -16px;
    left: -13px;

    &.active {
      display: block;
      svg {
        display: block;
      }
    }
  }
`

class Hand extends React.Component {
  timeout = null
  snoringTimeout = null
  iconRef = null
  currentIndex = 0
  sleeping = false
  snoring = false

  constructor(props) {
    super(props)
    this.iconRef = React.createRef()
    this.lottieRef = React.createRef()
  }

  sleep = ms => {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  mouseHandler = async () => {
    clearTimeout(this.timeout)
    if (this.timeout) {
      clearTimeout(this.timeout)
      this.timeout = null
    }
    if (this.props.forceSleep) {
    } else {
      if (this.sleeping) {
        this.sleeping = false
        this.awakeHand()
      }
      this.timeout = setTimeout(async () => {
        this.sleeping = true
        this.sleepHand()
      }, 5000)
    }
  }

  startSnoring = first => {
    this.snoringTimeout = setTimeout(
      () => {
        if (this.snoring) {
          const el = document.getElementById("lottieEl")
          el.classList.add("active")
          this.lottieRef.current.anim.setSpeed(0.9)
          this.lottieRef.current.anim.goToAndPlay(0)
        }
      },
      first ? 5000 : 2000
    )
  }

  sleepHand = async () => {
    for (let index = this.currentIndex; index <= 17; index++) {
      if (this.sleeping) {
        this.iconRef.current.setAttribute("data-active", index)
        this.currentIndex = index
        if (
          this.lottieRef &&
          this.lottieRef.current &&
          this.lottieRef.current.anim
        ) {
          if (index === 17) {
            this.snoring = true
            this.startSnoring(true)
          }
        }

        await this.sleep(30)
      }
    }
  }

  awakeHand = async () => {
    if (
      this.lottieRef &&
      this.lottieRef.current &&
      this.lottieRef.current.anim
    ) {
      this.snoring = false
      clearTimeout(this.snoringTimeout)
      const el = document.getElementById("lottieEl")
      el.classList.remove("active")
      this.lottieRef.current.anim.stop()
    }
    for (let index = this.currentIndex; index >= 0; index--) {
      if (!this.sleeping) {
        this.iconRef.current.setAttribute("data-active", index)
        this.currentIndex = index
        await this.sleep(30)
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.forceSleep && !this.props.forceSleep) {
      this.sleeping = true
      this.sleepHand()
    }
    if (!nextProps.forceSleep && this.props.forceSleep) {
      if (this.currentIndex > 0) {
        this.sleeping = false
        this.awakeHand()
      }
    }
  }

  componentDidMount() {
    document.addEventListener("scroll", this.mouseHandler)
    document.addEventListener("mousemove", this.mouseHandler)
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
    document.removeEventListener("mousemove", this.mouseHandler)
    document.removeEventListener("scroll", this.mouseHandler)
  }

  render() {
    const { forceSleep } = this.props
    let webpChecked = false
    let webpAccepted = false

    if (!isNode && document) {
      webpChecked = document
        .getElementsByTagName("html")[0]
        .classList.contains("webp-checked")
      webpAccepted = document
        .getElementsByTagName("html")[0]
        .classList.contains("support-webp")
    }
    return (
      <Wrapper className={`hand ${forceSleep ? "force" : ""}`}>
        <Icon key="black">
          <div className="hand-wrapper">
            <img
              ref={this.iconRef}
              alt="Hand animation"
              id="hand-sprite"
              src={webpChecked && webpAccepted ? spriteWebp : sprite}
            />
          </div>
          <div className="z" id="lottieEl">
            <Lottie
              ref={this.lottieRef}
              options={defaultOptions}
              height={80}
              width={80}
              eventListeners={[
                {
                  eventName: "complete",
                  callback: () => {
                    if (this.snoring) {
                      const el = document.getElementById("lottieEl")
                      el.classList.remove("active")
                      this.startSnoring()
                    }
                  },
                },
              ]}
            />
          </div>
        </Icon>
      </Wrapper>
    )
  }
}

export default Hand
