import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import Flex from "src/components/UI/Layout/Flex"
import Section from "src/components/UI/Layout/Section"
import Container from "src/components/UI/Layout/Grid/Container"
import Row from "src/components/UI/Layout/Grid/Row"
import Column from "src/components/UI/Layout/Grid/Column"
import LinkComponent from "src/components/Components/LinkComponent"
import SbEditable from "storyblok-react"
import menujson from "src/json/menu.json"
import Hand from "src/components/Components/Hand/Hand"
import Markdown from "src/storyblok/Components/Markdown"
import { checkColorHex } from "src/helpers/checkColorHex"
import { Above } from "src/components/UI/Breakpoints"
import { enableBodyScroll, disableBodyScroll } from "body-scroll-lock"
import isNode from "is-node"
import { getScaledFontSize } from "src/helpers/getScaledFontSize"

const Button = styled(Flex)`
  position: fixed;
  bottom: 15%;
  right: 0;
  z-index: 120;
  width: 80px;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  // transform: scale(1.1);
  mix-blend-mode: difference;

  @media (min-width: ${props => props.theme.breakpoints[2]}) {
    // transform: scale(1.2);
    bottom: auto;
    top: 0;
  }

  &:active,
  &:focus {
    outline: none;
  }

  &.active {
    mix-blend-mode: normal;
    #hand-sprite {
      filter: brightness(0%);
    }

    svg {
      g {
        fill: #000000 !important;

        path {
          fill: #000000 !important;
        }
      }
    }
  }
`

const Wrapper = styled(Section)`
  ${props =>
    props.theme.mixins.transition(
      "opacity",
      "0.3s",
      "cubic-bezier(0.42, 0, 0.35, 1)",
      "0.3s"
    )}
  pointer-events: none;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  right: 0;
  height: 100%;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  width: 100vw;
  overflow: auto;
  z-index: 100;
  will-change: transform;
  opacity: 0;

  &.active {
    ${props => props.theme.mixins.transition("opacity", "0.3s")}
    pointer-events: auto;
    opacity: 1;
  }

  .navigation a {
    text-decoration: none;
    margin-bottom: 0;
    line-height: 1.5em;
    ${props => props.theme.mixins.fontBedowDisplayHand}
    font-size: 50px;

    @media (min-width: ${props => props.theme.breakpoints[2]}) {
      ${props => props.theme.mixins.transition("font-variation-settings")}
      font-size: ${props =>
        getScaledFontSize(
          `${props.navLength ? 400 / props.navLength + "px" : "100px"}`,
          "768px",
          3
        )};
      margin-bottom: 48px;
      line-height: 0.85em;

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        ${props =>
          props.theme.mixins.transition(
            "font-variation-settings",
            "0.3s",
            "cubic-bezier(0.42, 0, 0.35, 1)"
          )}

        font-variation-settings: "GRAD" 0;
      }
    }
  }
`

const Menu = ({ blok, isEditor, location }) => {
  if (!isEditor) {
    blok = menujson.content
  }

  const wrapperRef = useRef(null)
  const [active, setActiveState] = useState(isEditor ? true : false)

  useEffect(() => {
    let element = null
    if (wrapperRef) {
      element = wrapperRef.current
    }
    if (active) {
      document.getElementsByTagName("html")[0].classList.add("menu-active")
      disableBodyScroll(element)
    } else {
      document.getElementsByTagName("html")[0].classList.remove("menu-active")
      enableBodyScroll(element)
    }
  }, [active])

  const { navigation, text, color_background, color_text } = blok
  const current = location
    ? location.pathname.slice(1, location.pathname.length)
    : null
  return (
    <SbEditable content={blok}>
      <Wrapper
        ref={wrapperRef}
        justifyContent={["flex-start", null, null, "center"]}
        className={active ? "active" : ""}
        py={[4, null, null, "24px"]}
        bg={checkColorHex(color_background, "background")}
        color={checkColorHex(color_text, "text")}
        navLength={navigation.length}
      >
        <Container>
          <Row>
            <Column
              className={"navigation"}
              width={[5 / 6, null, null, 11 / 12]}
              ml={[`${(1 / 6) * 100}%`, null, null, `${(1 / 12) * 100}%`]}
            >
              <Row>
                <Column width={[1, null, null, 5 / 12]}>
                  <Row
                    flexDirection="column"
                    justifyContent="space-between"
                    flex="1"
                  >
                    <Column width={1}>
                      <Above breakpoint="desktop">
                        <Row>
                          <Column width={2 / 5} />
                        </Row>
                      </Above>
                    </Column>
                    <Column width={1}>
                      <Above breakpoint="desktop">
                        <Row>
                          <Column width={4 / 5}>
                            <Markdown blok={{ text }} />
                          </Column>
                        </Row>
                      </Above>
                    </Column>
                  </Row>
                </Column>
                {navigation && navigation.length > 0 && (
                  <Column width="auto">
                    {navigation.map(item => (
                      <LinkComponent
                        key={item._uid}
                        onClick={e => {
                          setActiveState(false)
                          if (item.link.cached_url === current) {
                            e.preventDefault()
                            return false
                          }
                        }}
                        blok={item}
                      >
                        {item.text}
                      </LinkComponent>
                    ))}
                  </Column>
                )}
              </Row>
            </Column>
          </Row>
        </Container>
      </Wrapper>

      <Button
        aria-label="Menu button"
        onClick={() => setActiveState(!active)}
        as="button"
        className={active ? "active" : ""}
        py={["12px", null, null, "32px"]}
        px={["12px", null, null, "26px"]}
      >
        {!isNode && <Hand forceSleep={active} />}
      </Button>
    </SbEditable>
  )
}

export default Menu
