import React from "react"
import Layout from "src/components/Layout"
import Wrapper from "src/components/Wrapper"

export const wrapRootElement = Wrapper

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <Layout {...props}>{element}</Layout>
}

const supportsWebp = async () => {
  if (!window.createImageBitmap) return false

  const webpData =
    "data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA="
  const blob = await fetch(webpData).then(r => r.blob())

  return createImageBitmap(blob).then(() => true, () => false)
}

export const onClientEntry = async () => {
  // NOTE: Don't polyfill Promise here (Gatsby already includes a Promise polyfill)

  // IntersectionObserver polyfill for gatsby-image (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    require(`intersection-observer`)
    // console.log(`👍 IntersectionObserver is polyfilled`)
  }

  // Check if client supports webP
  if (document) {
    const htmlEL = document.getElementsByTagName("html")[0]

    // Check if client supports webP
    if (await supportsWebp()) {
      htmlEL.classList.add("webp-checked")
      htmlEL.classList.add("support-webp")
    } else {
      htmlEL.classList.add("webp-checked")
    }
  }

  if ("serviceWorker" in navigator) {
    // navigator.serviceWorker.ready.then(registration => {
    //   registration.unregister()
    // })
    navigator.serviceWorker.getRegistration().then(function(registration) {
      if (registration) {
        registration.unregister().then(function(success) {
          // if success = true, unregister was successful
        })
      }
    })
  }
  if ("caches" in window) {
    caches.keys().then(function(keyList) {
      return Promise.all(
        keyList.map(function(key) {
          return caches.delete(key)
        })
      )
    })
  }
}

export const onServiceWorkerUpdateFound = () => {
  window.location.reload()
}

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  setTimeout(() => {
    window.scrollTo(0, 0)
  }, 500)
  return false
}
