import React, { useState } from "react"
import { useCookies } from "react-cookie"
import styled from "styled-components"
import posed, { PoseGroup } from "react-pose"
import Section from "src/components/UI/Layout/Section"
import Container from "src/components/UI/Layout/Grid/Container"
import Row from "src/components/UI/Layout/Grid/Row"
import Column from "src/components/UI/Layout/Grid/Column"

const Wrapper = styled(Section)`
  position: fixed !important;
  color: black;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translate(0);
  z-index: 999;
  width: 256px;
  height: 247px;
  max-width: 100%;
  background: ${props => props.theme.colors.green};
  clip-path: polygon(0 0, 0% 100%, 100% 100%);
`

const Button = styled.button`
  ${props => props.theme.mixins.buttonDefault}
  ${props => props.theme.mixins.fontSizeBody}
  ${props => props.theme.mixins.fontBedowTextHead}
    color: black;
    text-decoration: underline;
    margin: 0 -8px -8px -8px;
    padding: 8px 8px;
    height: 100%;
    display: flex;

  @media (min-width: ${props => props.theme.breakpoints[2]}) {
    margin: 0 -12px;
    padding: 0 12px;
  }
`

const PosedWrapper = posed(Wrapper)({
  enter: {
    transform: "translateY(0) translateX(0)",
  },
  exit: {
    transform: "translateY(100%) translateX(-100%)",
    transition: { duration: 800 },
  },
})
const CookieBar = () => {
  const [cookies, setCookie] = useCookies(["bedow"])
  const [submitted, setSubmitted] = useState(false)

  function onClick(cookie) {
    setSubmitted(true)
    setTimeout(function() {
      setCookie("bedow", cookie, { path: "/" })
    }, 1000)
  }

  return !cookies.bedow ? (
    <div>
      <PoseGroup>
        {!submitted && (
          <PosedWrapper
            key="cookie"
            pt={["12px", null, null, "12px"]}
            px={["12px", null, null, "24px"]}
            justifyContent="flex-end"
          >
            <Container>
              <Row>
                <Column width={1}>
                  <Row mb={2} width={[6 / 6]}>
                    This website
                    <br /> uses cookies to
                    <br /> help it run smoothly
                  </Row>
                  <Row mb={["12px", null, null, 3]} width={[3 / 6]}>
                    <Button onClick={() => onClick(true)}>Continue</Button>
                  </Row>
                </Column>
              </Row>
            </Container>
          </PosedWrapper>
        )}
      </PoseGroup>
    </div>
  ) : null
}

export default CookieBar
