import Flex from "src/components/UI/Layout/Flex"
import styled from "styled-components"

const Row = styled(Flex)``

Row.defaultProps = {
  mx: ["-6px", null, null, "-12px"],
  flexDirection: ["column", null, null, "row"],
  flexWrap: "wrap",
}

export default Row
