import { css } from "styled-components"

// Bedow Text
import BedowTextGXTtf from "./Bedow/BedowHandGX.ttf"

import BedowTextHeadWoff from "./Bedow/BedowText-Head.woff"
import BedowTextHeadWoff2 from "./Bedow/BedowText-Head.woff2"
import BedowTextHeadEot from "./Bedow/BedowText-Head.eot"
import BedowTextHeadOtf from "./Bedow/BedowText-Head.otf"

import BedowTextHandWoff from "./Bedow/BedowText-Hand.woff"
import BedowTextHandWoff2 from "./Bedow/BedowText-Hand.woff2"
import BedowTextHandEot from "./Bedow/BedowText-Hand.eot"
import BedowTextHandOtf from "./Bedow/BedowText-Hand.otf"

// Bedow Display
import BedowDisplayGXTtf from "./Bedow/BedowHeadGX.ttf"

import BedowDisplayHeadWoff from "./Bedow/BedowDisplay-Head.woff"
import BedowDisplayHeadWoff2 from "./Bedow/BedowDisplay-Head.woff2"
import BedowDisplayHeadEot from "./Bedow/BedowDisplay-Head.eot"
import BedowDisplayHeadOtf from "./Bedow/BedowDisplay-Head.otf"

import BedowDisplayHandWoff from "./Bedow/BedowDisplay-Hand.woff"
import BedowDisplayHandWoff2 from "./Bedow/BedowDisplay-Hand.woff2"
import BedowDisplayHandEot from "./Bedow/BedowDisplay-Hand.eot"
import BedowDisplayHandOtf from "./Bedow/BedowDisplay-Hand.otf"

export default css`
  /* Bedow Text Font */
  @font-face {
    font-family: "Bedow-Text-Head";
    src: url(${BedowTextGXTtf}) format("truetype");
    font-style: normal;
    font-display: block;
  }

  @font-face {
    font-family: "Bedow-Head-Text";
    src: url(${BedowTextHeadEot}); /* IE9 Compat Modes */
    src: url(${BedowTextHeadEot}?#iefix) format("embedded-opentype"),
      url(${BedowTextHeadWoff}) format("woff"),
      url(${BedowTextHeadWoff2}) format("woff2"),
      url(${BedowTextHeadOtf}) format("otf");
    font-weight: 300;
    font-style: normal;
    font-display: block;
  }

  @font-face {
    font-family: "Bedow-Text-Hand";
    src: url(${BedowTextGXTtf}) format("truetype");
    font-style: normal;
    font-display: block;
  }

  @font-face {
    font-family: "Bedow-Hand-Text";
    src: url(${BedowTextHandEot}); /* IE9 Compat Modes */
    src: url(${BedowTextHandEot}?#iefix) format("embedded-opentype"),
      /* IE6-IE8 */ url(${BedowTextHandWoff}) format("woff"),
      url(${BedowTextHandWoff2}) format("woff2"),
      url(${BedowTextHandOtf}) format("otf");
    font-weight: 300;
    font-style: normal;
    font-display: block;
  }

  /* Bedow Display Font */
  @font-face {
    font-family: "Bedow-Display-Head";
    src: url(${BedowTextGXTtf}) format("truetype");
    font-style: normal;
    font-display: block;
  }

  @font-face {
    font-family: "Bedow-Head-Display";
    src: url(${BedowDisplayHeadEot}); /* IE9 Compat Modes */
    src: url(${BedowDisplayHeadEot}?#iefix) format("embedded-opentype"),
      url(${BedowDisplayHeadWoff}) format("woff"),
      url(${BedowDisplayHeadWoff2}) format("woff2"),
      url(${BedowDisplayHeadOtf}) format("otf");
    font-weight: 300;
    font-style: normal;
    font-display: block;
  }

  @font-face {
    font-family: "Bedow-Display-Hand";
    src: url(${BedowDisplayGXTtf}) format("truetype");
    font-style: normal;
    font-display: block;
  }

  @font-face {
    font-family: "Bedow-Hand-Display";
    src: url(${BedowDisplayHandEot}); /* IE9 Compat Modes */
    src: url(${BedowDisplayHandEot}?#iefix) format("embedded-opentype"),
      /* IE6-IE8 */ url(${BedowDisplayHandWoff}) format("woff"),
      url(${BedowDisplayHandWoff2}) format("woff2"),
      url(${BedowDisplayHandOtf}) format("otf");
    font-weight: 300;
    font-style: normal;
    font-display: block;
  }
`
