import Flex from "src/components/UI/Layout/Flex"
import styled from "styled-components"

const Section = styled(Flex)``

Section.defaultProps = {
  as: "section",
  flexDirection: "column",
  px: ["6px", null, null, "12px"],
}

export default Section
